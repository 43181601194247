<template>
    <div class="chat">
      <div class="chat-head">
        <div class="chat-head-text">
          <div class="chat-head-text-list">
            <p>
              <span>{{ $t('INSPECTION_CHAT_HEADER_REQUEST_NO_LABEL') }}</span>
              <span class="fontweight-n">{{requestItem.manageNo}}</span>
            </p>
          </div>
          <div class="chat-head-text-list">
            <p>
              <span>{{ $t('INSPECTION_CHAT_HEADER_MAKER_LABEL') }}</span>
              <span class="fontweight-n">{{ requestItem.makerName }}</span>
            </p>
          </div>
          <div class="chat-head-text-list">
            <p>
              <span>{{ $t('INSPECTION_CHAT_HEADER_BIKE_TYPE_LABEL') }}</span>
              <span class="fontweight-n">{{requestItem.bikeType}}</span>
            </p>
          </div>
          <div v-if="bidChatFlg === true" class="chat-head-text-list">
            <p>
              <span>{{ $t('INSPECTION_CHAT_HEADER_DELIVERY_LABEL') }}</span>
              <span class="fontweight-n">{{requestItem.delivery}}</span>
            </p>
          </div>
        </div>
        <div class="chat-head-btnWrap">
          <a class="chat-head-btn blue" @click="$router.go(-1)">{{$t("MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL")}}</a>
        </div>
      </div>
      <div class="chat-body">
        <div class="chat-body-wrap" id="chat-area">
          <div v-for="(item, i) in chatMessageData" :key="i">
            <div v-if="item.message_flag === true" class="chat-body-detail">
              <p class="chat-body-detail-a">{{item.member_nickname}}</p>
              <p class="chat-body-detail-text seller" style="white-space: pre-line;">{{item.message}}</p>
            </div>
            <div v-else class="chat-body-detail">
              <p v-if="item.member_no === null" class="chat-body-detail-q">{{ $t('INSPECTION_CHAT_ADMIN_NAME_LABEL') }}</p>
              <p v-else class="chat-body-detail-q">{{item.member_nickname}}</p>
              <p class="chat-body-detail-text user" style="white-space: pre-line;">{{item.message}}</p>
              <div class="chat-body-detail-btnWrap">
                <div v-if="item.message_flag === false && item.member_no !== null" class="chat-tooltip">
                  <v-tooltip bottom
                    :color="'primary'">
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        dark
                        v-bind="attrs"
                        v-on="on"
                        src="@/assets/img/common/ic_report.svg"
                        @click="selectMessageNo = item.successfull_message_no; selectBadMemberNo = item.member_no; report(item.report_flag)"
                      >
                    </template>
                    <span v-if="item.report_flag === false" class="chat-tooltip-text">通報する</span>
                    <span v-else class="chat-tooltip-text">通報済み</span>
                  </v-tooltip>
                </div>
                <a v-if="item.checked_user_no === null" class="chat-body-detail-btn blue" @click="selectMessageNo = item.request_message_no || item.successfull_message_no; chatStore.checkedMessage()">確 認</a>
              </div>
            </div>
          </div>
        </div>
        <a @click="chatStore.getChatMessage()" class="btn-reload"><img src="@/assets/img/common/ic_reload.svg" alt=""></a>
      </div>

      <div class="chat-foot">
        <form class="chat-form" action="">
          <div v-if="errors.length > 0">
            <p class="err-txt">{{ errors }}</p>
          </div>
          <div class="chat-form-textarea">
            <textarea type="text" id="chatInput" placeholder="コメントを入力してください" v-model="inputMessage"></textarea>
          </div>
          <div class="chat-form-btnWrap">
            <button class="chat-form-btn blue" type="submit" @click="chatStore.regist">{{$t("INSPECTION_CHAT_POST_BUTTON_LABEL")}}</button>
            <button class="chat-form-btn lt_gray" type="reset" @click="reset">{{$t("INSPECTION_CHAT_CLEAR_BUTTON_LABEL")}}</button>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import useChat from '@/stores/useChat'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const chatStore = useChat()
    return {
      chatStore
    }
  },
  components : {
  },
  props : {
    isPreview : {
      type    : Boolean,
      default : false
    },
    requestResultData : {
      type    : Object,
      default : () => {}
    },
  },
  computed : {
    constants() {
      return this.chatStore.constants
    },
    requestItem() {
      return this.chatStore.requestItem
    },
    bidChatFlg() {
      return this.chatStore.bidChatFlg
    },
    chatMessageData() {
      return this.chatStore.chatMessageData
    },
    inputMessage : {
      get() {
        return this.chatStore.inputMessage
      },
      set(val) {
        this.chatStore.inputMessage = val
      }
    },
    selectMessageNo : {
      get() {
        return this.chatStore.selectMessageNo
      },
      set(val) {
        this.chatStore.selectMessageNo = val
      }
    },
    selectBadMemberNo : {
      get() {
        return this.chatStore.selectBadMemberNo
      },
      set(val) {
        this.chatStore.selectBadMemberNo = val
      }
    },
    errors() {
      return this.chatStore.errors
    },
  },
  methods : {
    reset() {
      this.inputMessage = null
    },
    report(report_flag) {
      if (report_flag === false) {
        this.chatStore.openReportComfirmDialog()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
form .form-wrap {
  align-items: start !important;
}
.selection-txt {
  margin-left: 10px;
}
.btn-reload{
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-top: 5px;
  img {
      width: 35px;
      height: 100%;
      vertical-align: middle;
    }
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss">
</style>
